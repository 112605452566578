@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* transition: 0.3s ease; */
    font-family: 'Plus Jakarta Sans', sans-serif;
    color: #2B2C32;
}