:root {
    --yellow-500: #CCA111;
    --yellow-400: #FFC915;
    --yellow-300: #FFDF73;
    --yellow-200: #FFE9A1;
    --yellow-100: #FFFAE8;

    --gray-300: #2B2C32;
    --gray-200: #787D8F;
    --gray-100: #D4D7E2;
    --gray-50: #F0F1F5;
    --white: #FFFFFF;
}