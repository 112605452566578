@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
@import './variable.css';

#root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.__ff-PJS {
    font-family: "Plus Jakarta Sans", sans-serif;
}

.color-gold-500 {
    color: var(--gold-500);
}

.__custom-range-slider .thumb {
    width: 20px;
    height: 20px;
    cursor: grab;
    border: 2px solid var(--blue-400);
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    margin-top: 2px;
}

.__custom-range-slider .track.track-0,
.__custom-range-slider .track.track-2 {
    height: 4px;
    background-color: var(--gray-100);
}
.__custom-range-slider .track.track-1 {
    height: 4px;
    background-color: var(--blue-400);
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.hide-scrollbar {
    scrollbar-width: none; 
    -ms-overflow-style: none; 
}